<template>
  <div>
    <nuxt-link
      v-if="pdpLink"
      :prefetch="true"
      :to="product.url"
      class="product-main"
      @click.native="$emit('detail-clicked', product)"
    >
      <NarrativeProductCardImage :product="product" />
      <NarrativeProductCardTagLine
        :product="product"
        :extra-savings="extraSavings"
      />
    </nuxt-link>
    <a
      v-else
      href="#"
      class="product-main"
      @click.prevent="$emit('detail-clicked', product)"
    >
      <NarrativeProductCardImage :product="product" />
      <NarrativeProductCardTagLine
        :product="product"
        :extra-savings="extraSavings"
      />
    </a>
  </div>
</template>

<script>
export default {
  name: 'NarrativeProductCardDisplay',
  components: {
    NarrativeProductCardImage: () =>
      import('~/components/Product/Card/NarrativeProductCardImage.vue'),
    NarrativeProductCardTagLine: () =>
      import('~/components/Product/Card/NarrativeProductCardTagLine.vue'),
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    extraSavings: {
      type: String,
      required: false,
      default: null,
    },
    pdpLink: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
